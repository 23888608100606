import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {

  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - धुन" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='धुन'/>
        <div id="main">
          <section id="content" className="main">
            <p>
॥ योगिराज गजानन महाराज धुन ॥< br />
< br />
ॐ नमो सद्गुरू । श्री गजानन बाबा ।< br />
श्री गजानन बाबा । श्री गजानन बाबा ॥< br />
< br />
दिगंबरा गजानना । यावे तू रक्षणा ॥< br />
< br />
गुरू गजानन या हो । स्वामी मला भेट द्या हो ॥< br />
< br />
मला गजानन छंद । जो करी नामजपात धुंद ॥< br />
< br />
मुखे गजानन बोला । गण गण गणात बोते बोला ॥< br />
< br />
स्वामींचा आमच्या घरी वास । षड्‌रिपुंचा होतो त्याने नाश ॥< br />
< br />
माझा गजानन भोळा । स्मरता दर्शन वेळोवेळा ॥< br />
< br />
गोपाळा गोपाळा । गजानन बाबा गोपाळा ।< br />
साईबाबा गोपाळा । गजानन बाबा गोपाळा ॥ < br />
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
